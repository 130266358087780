import React from 'react'
import { InventorymanagementStyles } from './Inventorymanagement.styles'
import Image from 'components/ImgOptimized'
import Casequotesec from 'components/Casequotesec/Casequotesec'

function Inventorymanagement(props) {
  const { InventorymanagementProps } = props.Inventorymanagementdata
  const caseQuoteProp = InventorymanagementProps.caseQuoteProps
  const {
    data: {
      backhostedArchitech: {
        childImageSharp: { fluid: backhostedArchitech },
      },
    },
  } = props
  return (
    <InventorymanagementStyles className="InventorymanagementSec">
      <div className="container">
        <div className="businessHeading">
          <h2>{InventorymanagementProps.title}</h2>
        </div>
        <div className="engagementContent">
          <div className="left-part">
            <div className="bottom-content">
              <h4>{InventorymanagementProps.bottomTitle}</h4>
              <p>{InventorymanagementProps.bottomContent}</p>
            </div>
          </div>
          <div className="right-part">
            <p>{InventorymanagementProps.maincontent}</p>
            <figure>
              <Image
                fluid={backhostedArchitech}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 557,
                }}
              />
              <div className="img-caption">
                {InventorymanagementProps.imgCaption}
              </div>
            </figure>
            <p>{InventorymanagementProps.bottompara}</p>
          </div>
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </InventorymanagementStyles>
  )
}

export default Inventorymanagement
