import styled from 'styled-components'
import { lg, md, xl, sm } from 'config/variables'

export const ScreenlistwithcontentStyles = styled.div`
  .content-list-wrap {
    display: flex;
    justify-content: flex-end;
    .content-list {
      flex: 0 1 867px;
      ${xl(`
        flex: 0 1 821px;
     `)}
     ${lg(`
        flex: 0 1 536px;
     `)}
     ${md(`
        flex:1 1 auto;
     `)}
      .img-frame{
        width: 100%;
        margin: 56px 0 37px;
        position: relative;
        user-select: none;
        ${xl(`
             max-width:821px;
             margin:0 auto;
        `)}  
        ${lg(`
            max-width:821px;
            margin: 50px auto;
        `)} 
         ${md(`
          max-width:420px;
            margin: 30px auto;
        `)} 
        ${sm(`
            max-width:280px;
           
        `)} 
        .wrapper-img{
          width: calc(100% - 108px);
          height: calc(100% - 104px);
          position: absolute;
          top: 33px;
          left: 38px;
          ${xl(`
            width: calc(100% - 97px);
            height: calc(100% - 104px);
            position: absolute;
            top: 31px;
            left: 35px;
          `)} 
          ${lg(`
            width: calc(100% - 69px);
            height: calc(100% - 73px);
            position: absolute;
            top: 22px;
            left: 25px;
          `)} 
          ${md(`
               width: calc(100% - 57px);
              height: calc(100% - 60px);
              top: 20px;
              left: 21px;
          `)} 
          ${sm(`
            width: calc(100% - 38px);
            height: calc(100% - 42px);
            top: 13px;
            left: 14px;
          `)} 
          .after, .before{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            overflow: hidden;
            user-select: none;
            img{
              height: 100%;
              max-width: min-content;
              pointer-events: none;
            }
          }
          .scroller{
            background: #35C13C;
            width: 10px;
            height: calc(100% + 80px);
            border-radius: 5px;
            position: absolute;
            top: -35px;
            cursor: pointer;
            pointer-events: auto;
            ${md(`
              top: -30px;
              height: calc(100% + 60px);
            `)} 
          }
        }
      }      
    }
  }
  .full-img {
    width: calc(100% + 48px);
    margin: 74px -24px 20px;
    ${xl(`
        margin: 40px -24px 0;
     `)}
     ${lg(`
        margin: 30px -15px 0;
        width: calc(100% + 30px);
     `)}
     ${md(`
        margin: 0px 0 0;
        width: 100%;
     `)}
  }
`
