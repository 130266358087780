import React, { useRef, useEffect } from 'react'
import { ScreenlistwithcontentStyles } from './Screenlistwithcontent.styles'
import Image from 'components/ImgOptimized'
import { imgSlider } from 'components/before-after-imgSlider'
import Casequotesec from 'components/Casequotesec/Casequotesec'
import { images } from 'config/images'

function Screenlistwithcontent(props) {
  const { ScreenlistwithcontentProps } = props.Screenlistwithcontentdata
  const caseQuoteProp = ScreenlistwithcontentProps.caseQuoteProps
  const {
    data: {
      stubbsWoottonScreens: {
        childImageSharp: { fluid: stubbsWoottonScreens },
      },
    },
  } = props

  const wrapperRef = useRef()
  useEffect(() => {
    if (wrapperRef.current) {
      imgSlider()
    }
  }, [])
  return (
    <ScreenlistwithcontentStyles>
      <div className="container">
        <h2>{ScreenlistwithcontentProps.heading}</h2>
        <div className="content-list-wrap">
          <div className="content-list">
            <p>{ScreenlistwithcontentProps.topPara}</p>
            <div className="img-frame">
              <img src={images.iPadPro} alt="" />
              <div ref={wrapperRef} className="wrapper-img">
                <div className="before">
                  <img src={images.stubbsNew} alt="" />
                </div>
                <div className="after">
                  <img src={images.stubbsOld} alt="" />
                </div>
                <div className="scroller"></div>
              </div>
            </div>
            <p>{ScreenlistwithcontentProps.bottomPara}</p>
          </div>
        </div>
        <figure className="full-img">
          <Image
            fluid={stubbsWoottonScreens}
            lazyload={{
              enabled: true,
              once: true,
              offset: 1000,
              heigth: 451,
            }}
          />
        </figure>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </ScreenlistwithcontentStyles>
  )
}

export default Screenlistwithcontent
