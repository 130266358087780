import React from 'react'
import { ResultfullimgStyles } from './Resultfullimg.styles'
import Image from 'components/ImgOptimized'

function Resultfullimg(props) {
  const { ResultfullimgProps } = props.resultfullimgdata
  const {
    data: {
      monogramImg: {
        childImageSharp: { fluid: monogramImg },
      },
    },
  } = props
  return (
    <ResultfullimgStyles className="ResultfullimgSec">
      <div className="container">
        <div className="row">
          <div className="col-head">
            <h2>{ResultfullimgProps.heading}</h2>
          </div>
          <div className="col-content">
            <p>{ResultfullimgProps.para}</p>
          </div>
        </div>
        {ResultfullimgProps.videoURL ? (
          <div className="video-blk">
            <video autoPlay muted loop playsInline>
              <source src={ResultfullimgProps.videoURL} type="video/mp4" />
            </video>
          </div>
        ) : (
          ''
        )}
        <figure>
          <Image
            fluid={monogramImg}
            lazyload={{
              enabled: true,
              once: true,
              offset: 1000,
              heigth: 451,
            }}
          />
        </figure>
      </div>
    </ResultfullimgStyles>
  )
}

export default Resultfullimg
