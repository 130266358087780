import React from 'react'
import { images } from 'config/images'

export const StubbsWoottonsProps = {
  bannerProps: {
    heading: 'Stubbs & Woottons',
    bannerPara: 'helping luxury shoe brand go digital',
    BannerAlt: 'Stubbs & Woottons Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        The overall luxury market has grown by 20% since 2017, to an estimated
        $1.35 trillion globally with shoe being the fastest growing product
        category in personal luxury goods market. <br />
        <br />
        Stubbs & Wootton is a luxury shoe brand making handmade loafers for more
        than 30 years. Their retail stores, mostly located in downtown posh
        areas have customers from Kanye West to other notable celebrities. They
        wanted to extend this luxury presence to online and digital world to
        attract more shoppers. Crafting handmade customized shoes sets them
        apart from others. The biggest goal for us was to seamlessly continue
        this tailored customer experience to online assets.
      </>
    ),
  },
  ReplicatingstoreProps: {
    heading: 'Replicating physical luxury store experience to the digital',
    para:
      'The battle to retain exclusivity and personal relationships with customers grows many fold when they buy from an online store. You need to craft features like browsing products, advanced filters options, checkout experience, user account management, return and refund mechanism, and loyalty programs by addressing the needs of the high-end customers.',
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “Simform has been a phenomenal partner with vast experience in
            e-commerce space and breadth of technical expertise
          </span>
          -- exactly what we needed to launch our online presence”
        </>
      ),
      userName: 'Ryan Trueblood',
      designation: 'Operations & Development Manager',
    },
  },
  ResultfullimgProps: {
    heading: 'Result',
    para: (
      <>
        In the end, Stubbs & Wootton got a rich online presence in-line with the
        brand’s overall look, feel, and positioning. We also built an iPad app
        running in Kiosk mode to take customized orders by applying various
        combinations of fabrics, motifs, and monograms based on the customer's
        preferences. <br />
        <br />
        This resulted in an increased awareness of the brand amongst luxury
        customers around the world. Noticeable rise in web traffic & the average
        value of purchases, and a significant uplift in sales was achieved
        within the first 6 months of launch.
      </>
    ),
    videoURL: images.stubbsVideo,
  },
  ScreenlistwithcontentProps: {
    heading: 'Bespoke shoes studio became more powerful with iPad kiosks',
    topPara:
      'Stubbs & Wootton has state of the art bespoke studios across the US where customers book an appointment to design and craft the shoes they love. Customizing their shoe range is difficult as there can be more than thousands of combinations of different fabrics, patterns, colors and motifs.',
    bottomPara:
      'We built an iPad kiosk and app solution that was to be used by Sales reps as well as customers. They use it to customize the shoes in an interactive immersive experience. For the mobile architecture, we used RxSwift library that defines how to behave when there are changes in the state of a system, user input or data coming from a stream. We followed the MVVM architecture pattern which is highly compatible with RxSwift and utilized local database with-in memory caching, so that shoe customization can be applied quickly as compared to a solution which relies upon responses from server.',
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “We are consistently looking to add value to our customer experience
            and as everyone is time poor we felt reducing shopping time would be
            a significant benefit to our customers.
          </span>{' '}
          Using technology has added that option to some of our busiest stores”
        </>
      ),
      userName: 'Ryan Trueblood',
      designation: 'Operations & Development Manager',
    },
  },
  CaseengagementsecProps: {
    title: 'Choosing a flexible E-commerce platform keeping future in mind',
    bottomTitle: 'Development',
    bottomContent: 'Powering website with Shopify store',
    maincontent: (
      <>
        Taking your physical store experience online is not as simple as it
        seems. Brand consistency, Ease of use, and flexibility to changing needs
        are the most important considerations. The last thing you want is to
        start over from scratch after having chosen the wrong platform. <br />
        <br />
        We are a Shopify and Magento ecommerce development partner. After
        considering, multiple platforms and frameworks we opted to use Shopify
        hosted platform. Shopify provides powerful integrations, API access, and
        security features satisfying all the requirements.
      </>
    ),
  },
  InventorymanagementProps: {
    title:
      'Integrating with their POS and ERP for seamless inventory management',
    bottomTitle: 'Architecture',
    bottomContent:
      'Enhancing the product search functionality by utilizing the Redis and ElasticSearch',
    maincontent:
      'Stubbs and Wootton uses Netsuite to manage all the inventories of ‘Ready to Wear’ shoes as well as various materials required for customization and ‘Clover Point of Sale’ to generate invoices for offline sales. Clover is configured to talk with Netsuite ERP, so that inventory gets updated automatically when any sale happens. ',
    imgCaption: 'Backend hosted on scalable EC2 instances to meet high demand',
    bottompara:
      'We helped integrate ecommerce solution with them so that the store does not have to rely on multiple systems to manage orders. Our system collaborated with Clover POS APIs which can send order details to POS which generates the invoice.',
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Redbull Photobooth',
        para: 'Shortcut to get featured on Redbull website',
        className: 'powder-blue',
        link: 'case-studies/redbull-photobooth/',
      },
      {
        title: 'FreeWire',
        para:
          'Building EV charging platform to revolutionize the way we power everything.',
        link: 'case-studies/freewire-scalable-vehicle-charging/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
