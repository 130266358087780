import styled from 'styled-components'
import { lg, xl } from 'config/variables'

export const ResultfullimgStyles = styled.div`
  margin-bottom: 126px;
  ${xl(`
        margin-bottom: 90px;
    `)}
    ${lg(`
        margin-bottom: 60px;
    `)}
    ${lg(`
        margin-bottom: 20px;
    `)}
  .row {
    display: flex;
    justify-content: space-between;
    ${xl(`
        flex-direction: column;
    `)}
    ${lg(`
        flex-direction: column;
    `)}
    .col-head {
      flex: 0 1 200px;
      ${xl(`
        flex: 1 1 auto;
      `)}
      ${lg(`
        flex: 1 1 auto;
      `)}
    }
    .col-content {
      flex: 0 1 867px;
      ${xl(`
          flex: 1 1 auto;
      `)}
      ${lg(`
          flex: 1 1 auto;
      `)}
    }
  }
  figure {
    margin: 0 0 42px;
  }
  .video-blk {
    width: 100%;
    margin-top: 74px;
    video {
      width: 100%;
      height: auto;
      display: block;
    }
  }  
`
