import React from 'react'
import { ReplicatingstoreStyles } from './Replicatingstore.styles'
import Casequotesec from 'components/Casequotesec/Casequotesec'
import Image from 'components/ImgOptimized'

function Replicatingstore(props) {
  const { ReplicatingstoreProps } = props.Replicatingstoredata
  const caseQuoteProp = ReplicatingstoreProps.caseQuoteProps
  const {
    data: {
      storeImages: {
        childImageSharp: { fluid: storeImages },
      },
    },
  } = props
  return (
    <ReplicatingstoreStyles>
      <div className="container">
        <h3>{ReplicatingstoreProps.heading}</h3>
        <p>{ReplicatingstoreProps.para}</p>
        <figure>
          <Image
            fluid={storeImages}
            lazyload={{
              enabled: true,
              once: true,
              offset: 1000,
              heigth: 451,
            }}
          />
        </figure>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </ReplicatingstoreStyles>
  )
}

export default Replicatingstore
