import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Casebanner from 'components/Casebanner/Casebanner'
import { StubbsWoottonsProps } from 'components/Props/stubbs-woottons'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import Replicatingstore from 'components/Replicatingstore/Replicatingstore'
import Resultfullimg from 'components/Resultfullimg/Resultfullimg'
import Screenlistwithcontent from 'components/Screenlistwithcontent/Screenlistwithcontent'
import Caseengagementsec from 'components/Caseengagementsec/Caseengagementsec'
import Inventorymanagement from 'components/Inventorymanagement/Inventorymanagement'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'

export const StubbsWoottonsPage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #1c4000;
          }
        }
      }
    }
  }
  .caseintro-section {
    margin-bottom: 47px;
  }
`

const StubbsWoottons = props => {
  const { data } = props
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Stubbs & Woottons"
        description="Stubbs & Woottons partnered with Simform to create a rich online presence through website & iPad app branding as the central elements."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/case-studies/stubbs-woottons/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/case-studies/stubbs-woottons/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <StubbsWoottonsPage>
        <Casebanner
          bannerdata={StubbsWoottonsProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={StubbsWoottonsProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Replicatingstore
            Replicatingstoredata={StubbsWoottonsProps}
            {...props}
          />
          <Resultfullimg resultfullimgdata={StubbsWoottonsProps} {...props} />
        </div>
        {showContent && (
          <>
            <Screenlistwithcontent
              Screenlistwithcontentdata={StubbsWoottonsProps}
              {...props}
            />
            <Caseengagementsec
              Caseengagementsecdata={StubbsWoottonsProps.CaseengagementsecProps}
            />
            <Inventorymanagement
              Inventorymanagementdata={StubbsWoottonsProps}
              {...props}
            />
            <Relatedcaselist
              relatedcaselistdata={StubbsWoottonsProps}
              {...props}
            />
            <Casecontactcardinfo
              Casecontactcardinfodata={StubbsWoottonsProps}
            />
          </>
        )}
      </StubbsWoottonsPage>
    </Layout>
  )
}

export default StubbsWoottons

export const pageQuery = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/StubbsWoottonsbanner@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/redbull-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/freewire-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    storeImages: file(relativePath: { regex: "/store-images@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    monogramImg: file(relativePath: { regex: "/monogramImg@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    stubbsWoottonScreens: file(
      relativePath: { regex: "/stubbs-wootton-screens@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1190) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    bespokeSimage: file(relativePath: { regex: "/bespoke-simage@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 925) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    backhostedArchitech: file(
      relativePath: { regex: "/backhosted-architech@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 867) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
