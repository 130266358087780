import styled from 'styled-components'
import { lg, md, xl } from 'config/variables'

export const ReplicatingstoreStyles = styled.div`
  figure {
    width: calc(100% + 50px);
    margin: 72px -25px 42px;
    ${xl(`
      margin: 60px -25px 0;
    `)}
     ${lg(`
        margin: 40px -20px 0;
        width: calc(100% + 40px);
     `)}
     ${md(`
        margin: 20px 0 0;
        width: 100%;
     `)}
  }
`
